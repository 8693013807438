




















import Vue from 'vue';

interface Data {
    data: String,
    ctrl: HTMLInputElement | null,
    isFocused: boolean
}
export default Vue.extend({
    name: 'MaterialInput',
    props: {
        id: { type: String, required: false },
        type: { type: String, required: false, default: 'text' },
        label: {type: String, required: true },
        value: String, // initial data passed via v-model
        note: {type: String, required: false },
        errors: { type: Array, required: false },
        disabled: { type: Boolean, required: false },
        maxlength: {type: String, required: false}
    },
    data(): Data {
        return {
            data: this.value, // copy of 'value' prop
            ctrl: null,
            isFocused: true
        }
    },
    computed: {
        isInvalid(): boolean {
            return this.errors != null && this.errors.length > 0;
        }
    },
    methods: {
        isEmpty(): boolean {
            return this.ctrl!.value == '';
        },
        onInput() {
            this.$emit('input', this.ctrl!.value);
        },
        onBlur(){
            if(this.isEmpty()){
                this.isFocused = false;
            }
            this.$emit('blur', this.ctrl!.value);
        }
    },
    mounted() {
        this.ctrl = this.$refs.input as HTMLInputElement;
        if(this.isEmpty()){
            this.isFocused = false;
        }
    }
});
